import './main.scss'
import $ from 'jquery'

$(function () {
  // Start all modules collapsed if accessible lessons > 80
  $('.course-curriculum-module-content.start-collapsed').hide()

  // Add click event listeners to the module headers
  $('.course-curriculum-heading').on('click', function () {
    // Toggle the module body
    $(this).next('.course-curriculum-module-content').slideToggle()

    // Change the arrow icon
    $(this)
      .find('.course-curriculum-heading__toggle-button')
      .toggleClass('active')
  })

  // Check if the URL ends with an id like '#course_module_212787' and open that module
  // Useful if the modules started collapsed
  const moduleId = window.location.hash
  if (moduleId.startsWith('#course_module_')) {
    // Open the module content
    const currentModule = document.querySelector(moduleId)
    currentModule.querySelector(
      '.course-curriculum-module-content'
    ).style.display = 'block'
    currentModule
      .querySelector('.course-curriculum-heading__toggle-button')
      .classList.add('active')
  }
})
